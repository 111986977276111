<template>
    <div
        class="max-w-[100vw] ooverflow-x-hidden md:ooverflow-x-auto min-h-screen bg-white dark:bg-stone-900 text-black dark:text-white flex flex-col"
    >
        <EIJNS_Header />
        <NuxtPage />
        <EIJNS_Passkey
            v-if="
                passkeyStorage.consent === null &&
                dayjs().isAfter(passkeyStorage.remindMe || 0)
            "
        />
    </div>
</template>
<script setup>
import { useLocalStorage } from '@vueuse/core';

import dayjs from 'dayjs';
import 'dayjs/locale/de';
dayjs.locale('de');

const runtimeConfig = useRuntimeConfig();
const passkeyStorage = useLocalStorage('eijns-passkey', {});
passkeyStorage.value.createdAt ??= dayjs();
passkeyStorage.value.version ??= runtimeConfig.public.package.version;
passkeyStorage.value.consent ??= null;
passkeyStorage.value.remindMe ??= null;

const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

const registerPushNotification = async () => {

    if ('serviceWorker' in navigator) {

        // Überprüfen, ob der Benutzer die Berechtigung für Push-Benachrichtigungen erteilt hat
        if (Notification.permission === 'denied') {
            console.warn("Benachrichtigungen sind blockiert. Bitte Benachrichtigungen im Browser zulassen.");
            return; // Stoppe die Registrierung, wenn Benachrichtigungen blockiert sind
        }

        // Prüfen, ob der Service Worker bereits registriert ist
        const existingRegistration = await navigator.serviceWorker.getRegistration('/serviceWorker.js');
        
        // Wenn keine Registrierung vorhanden oder Berechtigungen entzogen sind, führe die Registrierung erneut aus
        if (!existingRegistration || Notification.permission !== 'granted') {
            try {
                const registration = await navigator.serviceWorker.register('/serviceWorker.js');
                console.log('Service Worker erfolgreich registriert mit Scope:', registration);
                await subscribeUserToPush(registration, true); // Benutzer für Push-Benachrichtigungen abonnieren
            } catch (error) {
                console.error('Service Worker konnte nicht registriert werden:', error);
            }
        } else {
            // Wenn Registrierung vorhanden ist und Berechtigung erteilt, nur Push-Logik ausführen
            console.log('Service Worker ist bereits registriert:', existingRegistration);
            await subscribeUserToPush(existingRegistration);
        }
    }
};

// Funktion zum Verwalten der Push-Benachrichtigungslogik
const subscribeUserToPush = async (registration, welcomeNotification) => {
    try {
        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: runtimeConfig.public.env.WEBPUSHNOTIFICATIONS_VAPID_PUBLICKEY,
        });
        
        console.log('Service Worker Abonnent:', subscription);

        const { data, pending, error, refresh } = await useFetch('/api/v1/pushNotifications/subscribe', {
            method: 'POST',
            server: false,
            immediate: true,
            headers: {
                'Content-Type': 'application/json',
            },
            body: {
                push: subscription,
                welcomeNotification: welcomeNotification,
            },
            onRequest({ request, options }) {
                console.log('onRequest', request);
            },
            async onResponse({ request, response, options }) {
                console.log('Antwort:', response);
            },
            onRequestError({ request, options, error }) {
                console.error('Anfragefehler:', error);
            },
            onResponseError({ request, response, options }) {
                console.error('Antwortfehler:', error);
            }
        });
    } catch (err) {
        console.error('Benutzer konnte nicht abonniert werden:', err);
        throw createError(err);
    }
};



onBeforeMount(async () => {});

onMounted(() => {
    registerPushNotification()
});

// if (passkeyStorage.value.consent === null && dayjs().isAfter(passkeyStorage.value.remindMe || 0)) {
//     passkeys.value.show = true
//     passkeyStorage.value.remindMe = null
//     passkeyStorage.value.createdAt = dayjs()
// }
</script>
<style type="text/css"></style>
