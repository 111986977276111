<template>
    <UModal psrevent-close v-model="passkeys.show" :ui="{ width: 'max-w-2xl mx-12 2xl:mx-48'}">
        <UCard :uui="{ body: { base: '!p-6 overflow-y-auto max-h-[60vh]'}, header: { background: '!bg-red-600 dark:!bg-red-800' }}">
            <template #header>
                <div class="modal-header-wrap">
                    <h3 class="text-base font-semibold leading-6 text-neutral-900 dark:text-white flex flex-row items-center">
                        <UIcon class="w-8 h-8 mr-2" name="i-mdi-smartphone-key" />
                        Anmelden mit Passkeys
                    </h3>
                    <!-- <UButton variant="ghost" color="white" icon="i-mdi-close" class="modal-header-close" @click="passkeys.show = false" /> -->
                </div>
            </template>
            <!--<div class="min-h-[40vh]">
                <vue-json-pretty 
                    class="!text-[12px]"
                    itemHeight="20" 
                    :data="errorMessage" 
                />
            </div>-->
            <UCarousel ref="passkeyAssistant" v-slot="{ item, index }" :items="[0,1,2,3,4,5,6,7]" :ui="{ item: 'basis-full' }" class="pointer-events-none">
            </UCarousel>
            <div class="flex flex-col">
                <div v-show="passkeyAssistantPage === 1" class="flex flex-col">
                    <p>Passkeys sollen Passwörter und die mehrfache Anmeldung ersetzen und die Anmeldung sicherer und einfacher machen. Man autorisiert sich mit Fingerabdruck, Gesichtsscan, Bildschirmsperre oder Passwort-Manager.
                    <!-- Ein Passkey besteht aus zwei Teilen: einem öffentlichen Schlüssel auf dem Server und einem privaten Schlüssel auf deinem Gerät.<br>
                    Wenn du dich mit deinem Benutzernamen oder Emailadresse anmeldest, überprüft die Website, ob dein öffentlicher Schlüssel mit deinem privaten Schlüssel übereinstimmt. Dazu wirst du aufgefordert, dein Gerät zu entsperren, zum Beispiel mit deinem Fingerabdruck. Im nu wirst du ohne Umwege angemeldet. --></p>
                    <p class="my-2"><strong>Dein Browser scheint passkeys zu unterstützen. Hab keine Angst, klicke einfach auf weiter und folge dem Assistenten.</strong></p>
                    <p>Im nächsten Schritt solltest du eine Abfrage sehen, wo oder wie du dein Passkey speichern möchtest.</p>
                </div>

                <div v-show="passkeyAssistantPage === 2" class="w-full flex flex-col">
                    <UAlert 
                        v-if="!registrationResponse?.error"
                        color="gray"
                        icon="i-mdi-key-arrow-right"
                        variant="soft"
                        title="Entscheide dich"
                    >
                        <template #description>
                            <p class="mb-2">Halte ausschau nach einem Fenster deines Passwort-Managers oder der Aufforderung auf deinem Smartphone, und leg fest, wie oder wo du den Passkey speichern möchtest.</p>
                            <p class="font-bold">Sollte bereits ein Passkey auf deinem Gerät existieren, muss dieser vorher mit hoher Wahrscheinlichkeit gelöscht werden.</p>
                        </template>
                    </UAlert>
                    <UAlert 
                        v-else
                        color="red"
                        icon="i-mdi-shield-warning"
                        variant="soft"
                        title="Nanu, ein Fehler"
                    >
                        <template #description>
                            <p class="mb-2">Etwas ist bei der Registrierung schiefgelaufen.</p>
                            <p class="font-bold mb-4">Versuch es vielleicht einfach nochmal.</p>
                            <UButton 
                                @click="passkeyAuth.startAuthentication"
                                color="black"
                                variant="outline"
                                class="pointer-events-auto"
                            >Überspringen und gespeicherten Passkey ausprobieren</UButton>
                        </template>
                    </UAlert>
                </div>

                <div v-show="passkeyAssistantPage === 3" class="w-full">
                    <UAlert 
                        v-if="!registrationResponse?.error"
                        color="gray"
                        icon="i-mdi-cloud-clock"
                        variant="soft"
                        title="Mooooment"
                    >
                        <template #description>
                            <p class="mb-2">Warte kurz, dein Passkey wird deinem Benutzerkonto hinzugefügt und anschließend Verifiziert.</p>
                        </template>
                    </UAlert>
                    <UAlert 
                        v-else
                        color="red"
                        icon="i-mdi-cloud-warning"
                        variant="soft"
                        title="Nee, klappt nicht"
                    >
                        <template #description>
                            <p class="mb-2">Die Registrierung hat geklappt. Nun ist aber bei der Verifizierung des Passkeys etwas schiefgelaufen.</p>
                            <p class="font-bold mb-8">Vielleicht hilft einfache ein erneuter Versuch.</p>
                            <p class="font-mono break-all">{{ registrationResponse?.error?.message }}</p>
                        </template>
                    </UAlert>
                </div>

                <div v-show="passkeyAssistantPage === 4" class="w-full">
                    <UAlert 
                        color="green"
                        icon="i-mdi-shield-check"
                        variant="soft"
                        title="Prima"
                    >
                        <template #description>
                            <p class="mb-2">Dein Passkey wurde gespeichert und sollte nun gültig sein.</p>
                            <p class="font-bold">Probiere es am besten gleich aus.</p>
                        </template>
                    </UAlert>
                </div>

                <div v-show="passkeyAssistantPage === 5" class="w-full">
                    <UAlert 
                        v-if="!authenticationResponse?.check"
                        color="gray"
                        icon="i-mdi-shield-lock"
                        variant="soft"
                        title="Los gehts"
                    >
                        <template #description>
                            <p class="mb-2">Halte ausschau nach einem Fenster deines Passwort-Managers oder der Aufforderung auf deinem Smartphone, und wähle den Eintrag aus der Liste oder Bestätige es, indem du dein Gerät entsperrst.</p>
                        </template>
                    </UAlert>
                    <UAlert 
                        v-else
                        color="red"
                        icon="i-mdi-shield-warning"
                        variant="soft"
                        title="Schade, schade"
                    >
                        <template #description>
                            <p class="mb-2">Da stimmt etwas nicht.</p>
                            <p class="font-bold">Versuch es vielleicht einfach nochmal.</p>
                        </template>
                    </UAlert>
                </div>

                <div v-show="passkeyAssistantPage === 6" class="w-full">
                    <UAlert 
                        v-if="!verifyAuthenticationResponse?.error"
                        color="gray"
                        icon="i-mdi-clock-check"
                        variant="soft"
                        title="Gedulde dich bitte"
                    >
                        <template #description>
                            <p class="mb-2">Dein gespeicherter Passkey wird gerade überprüft.</p>
                        </template>
                    </UAlert>
                    <UAlert 
                        v-else
                        color="red"
                        icon="i-mdi-shield-warning"
                        variant="soft"
                        title="Schade, schade"
                    >
                        <template #description>
                            <p class="mb-2">Da stimmt etwas nicht.</p>
                            <p class="font-bold">Versuch es vielleicht einfach nochmal.</p>
                        </template>
                    </UAlert>
                </div>

                <div v-show="passkeyAssistantPage === 7" class="w-full">
                    <UAlert 
                        v-if="verifyAuthenticationResponse?.success"
                        color="green"
                        icon="i-mdi-shield-check"
                        variant="soft"
                        title="Richtig gutes Ding"
                    >
                        <template #description>
                            <p class="mb-2">Dein gespeicherter Passkey wurde überprüft und für sehr sehr gut befunden.</p>
                        </template>
                    </UAlert>
                    <UAlert 
                        v-else
                        color="red"
                        icon="i-mdi-shield-warning"
                        variant="soft"
                        title="Ohh nein"
                    >
                        <template #description>
                            <p class="mb-2">Sorry, da passt etwas nicht.</p>
                            <p class="font-bold mb-8">Versuch es einfach nochmal.</p>
                            <p class="font-mono break-all">{{ verifyAuthenticationResponse }}</p>
                        </template>
                    </UAlert>
                </div>
            </div>
            <template #footer>
                <div class="flex space-x-5">
                    <UButton 
                        v-if="passkeyAssistantPage === 1"
                        @click="passkeyStorage.remindMe = dayjs().add(1, 'month')"
                        color="gray" 
                        variant="ghost" 
                        label="Später" 
                        icon="i-mdi-schedule"
                    />
                    <div class="grow"></div>
                    <!-- <UButton 
                        @click="passkeyAuth.assistant.prev()"
                        color="primary" 
                        variant="solid" 
                        label="zurück" 
                    /> -->
                    <UButton 
                        v-if="passkeyAssistantPage === 2 && registrationResponse?.error"
                        @click="passkeyAuth.startRegistration"
                        color="red" 
                        variant="solid" 
                        label="Los, nochmal..." 
                    />
                    <UButton 
                        v-else-if="passkeyAssistantPage === 3 && registrationResponse?.error"
                        @click="passkeyAuth.startRegistration"
                        color="red" 
                        variant="solid" 
                        label="OK, nochmal..." 
                    />
                    <UButton 
                        v-else-if="passkeyAssistantPage === 4 && registrationResponse?.success"
                        @click="passkeyAuth.startAuthentication"
                        variant="solid" 
                        label="Lass mal probieren..." 
                    />
                    <UButton 
                        v-else-if="passkeyAssistantPage === 5 && authenticationResponse?.error"
                        @click="passkeyAuth.startAuthentication"
                        variant="solid" 
                        label="Nochmal versuchen..." 
                    />
                    <UButton 
                        v-else-if="passkeyAssistantPage === 7 && verifyAuthenticationResponse?.success"
                        @click="passkeyAuth.finish"
                        variant="solid" 
                        label="Ok, fertigwerden" 
                    />
                    <UButton 
                        v-else-if="passkeyAssistantPage === 1"
                        :loading="passkeyPending"
                        @click="passkeyAuth.startRegistration"
                        variant="solid" 
                        label="weiter" 
                    />
                    <UButton 
                        v-else
                        disabled
                        variant="solid" 
                        label="weiter" 
                    />
                </div>
            </template>
        </UCard>
    </UModal>
</template>
<script setup>

    import { useLocalStorage } from '@vueuse/core'
    import { startRegistration, startAuthentication, browserSupportsWebAuthn, browserSupportsWebAuthnAutofill, platformAuthenticatorIsAvailable } from '@simplewebauthn/browser';

    import dayjs from 'dayjs'
    import 'dayjs/locale/de'
    dayjs.locale('de')

    const appStore = useAppStore()
    const { contextMenu, globals, me, badge } = storeToRefs(appStore)
    // console.log('appStore', {...me.value.username})
    /*const error = useError()
    watch(error, (val) => {
        console.log('error', error)
    }, { deep: true })*/
    
    /* ROUTE LADEN */
    const route = useRoute();
    const router = useRouter();

    const passkeyStorage = await useLocalStorage('eijns-passkey', {})

    const passkeys = ref({
        show: false,
        data: {},
        // diff: dayjs().diff(passkeyStorage.value.createdAt, 'second'),
    })

    // const now = ref(dayjs())
    // watch(now, (val) => {
    //     passkeyStorage.value.remindMeDiff = dayjs().diff(passkeyStorage.value.createdAt, 'second')
    //     // passkeys.value.diff = val.diff(passkeyStorage.value.createdAt, 'second')
    //     setTimeout(() => {
    //         now.value = dayjs()
    //     }, 666)
    // }, { immediate: true })

    if (passkeyStorage.value.consent === null && dayjs().isAfter(passkeyStorage.value.remindMe || 0)) {
        passkeys.value.show = true
        passkeyStorage.value.remindMe = null
        passkeyStorage.value.createdAt = dayjs()
    }

    const passkeyAssistant = ref(null)
    const passkeyAssistantPage = ref(1)
    const passkeyPending = ref(null)
    const registrationResponse = ref({})
    const registrationOptions = ref({})
    const authenticationResponse = ref({})
    const authenticationOptions = ref({})
    const verifyAuthenticationResponse = ref({})

    const passkeyAuth = {
        // Reaktive Zustände
        // pending: ref(false),
        // passkeys: ref({
        //     diff: 0, // Beispielwert für deine Berechnungen
        // }),

        // Funktionen
        async startRegistration(opt) {
            // console.log(passkeyAuth.assistant)
            try {
                passkeyAssistantPage.value = 2
                passkeyPending.value = true
                registrationResponse.value = null
                
                await useFetch(`/api/v1/auth/passkey/registrationOptions`, {
                    method: 'GET',
                    server: false,
                    immediate: true,
                    async onResponse({ response }) {
                        registrationOptions.value = response._data;

                        try {
                            registrationResponse.value = await startRegistration({ optionsJSON: response._data });
                            await passkeyAuth.verifyRegistration({ 
                                ...registrationResponse.value, 
                                challenge: response._data.challenge
                            });
                        } catch (error) {
                            console.log(error)
                            registrationResponse.value = { error: error }
                            // useError(error, 'Can\'t start passkey registration');
                        }

                        passkeyPending.value = false
                    },
                    onRequestError({ error }) {
                        console.log(error)
                        registrationResponse.value = { error: error }
                        passkeyPending.value = false
                        // useError(error, 'Can\'t start passkey registration');
                    },
                    onResponseError({ response }) {
                        console.log(response._data)
                        registrationResponse.value = { error: response._data }
                        passkeyPending.value = false
                        // useError(response, 'Can\'t start passkey registration');
                    }
                });
            } catch(err) {
                console.log(err)
            }
        },

        async verifyRegistration(opt) {
            try {
                passkeyAssistantPage.value = 3
                passkeyPending.value = true
                registrationResponse.value = null

                await useFetch(`/api/v1/auth/passkey/verifyRegistration`, {
                    method: 'POST',
                    server: false,
                    immediate: true,
                    body: opt,
                    async onResponse({ response }) {
                        // Verarbeite die Antwort
                        passkeyAssistantPage.value = 4
                        registrationResponse.value = { success: true }
                        passkeyPending.value = false
                    },
                    onRequestError({ error }) {
                        console.log(error)
                        // useError(error, 'Can\'t verify passkey registration');
                        registrationResponse.value = { error: error }
                        passkeyPending.value = false
                    },
                    onResponseError({ response }) {
                        console.log(response._data)
                        // useError(response, 'Can\'t verify passkey registration');
                        registrationResponse.value = { error: response._data }
                        passkeyPending.value = false
                    }
                });
            } catch(err) {
                console.log(err)
            }
        },

        async startAuthentication() {
            try {
                passkeyAssistantPage.value = 5
                passkeyPending.value = true
                authenticationResponse.value = null

                await useFetch(`/api/v1/auth/passkey/authenticationOptions`, {
                    method: 'GET',
                    server: false,
                    immediate: true,
                    headers: {
                        'x-credential-identifier': me.value.username,
                    },
                    async onResponse({ response }) {
                        authenticationOptions.value = response._data;

                        try {
                            authenticationResponse.value = await startAuthentication({ optionsJSON: response._data });
                            await passkeyAuth.verifyAuthentication({ 
                                ...authenticationResponse.value, 
                                challenge: response._data.challenge,
                                identifier: me.value.username,
                            });
                        } catch (error) {
                            console.log(error)
                            // passkeyAuth.authenticationTestError();
                            authenticationResponse.value = { error: error }
                            // useError(error, 'Can\'t start passkey verification');
                        }

                        passkeyPending.value = false
                    },
                    onRequestError({ error }) {
                        console.log(error)
                        // passkeyAuth.authenticationTestError();
                        // useError(error, 'Can\'t start passkey verification');
                        authenticationResponse.value = { error: error }
                        passkeyPending.value = false
                    },
                    onResponseError({ response }) {
                        console.log(response._data)
                        // passkeyAuth.authenticationTestError();
                        // useError(response, 'Can\'t start passkey verification');
                        authenticationResponse.value = { error: response._data }
                        passkeyPending.value = false
                    }
                });
            } catch(err) {
                console.log(err)
            }
        },

        async verifyAuthentication(opt) {
            try {
                passkeyAssistantPage.value = 6
                passkeyPending.value = true
                verifyAuthenticationResponse.value = null

                await useFetch(`/api/v1/auth/passkey/verifyAuthentication`, {
                    method: 'POST',
                    server: false,
                    immediate: true,
                    body: opt,
                    headers: {
                        'x-credential-passkey-test': 'true',
                    },
                    async onResponse({ response }) {
                        // verifyAuthenticationResponse.value = response.ok

                        if (response.error) {
                            // console.log(error)
                            // useError(response, 'Can\'t verify passkey');
                            // passkeyAuth.authenticationTestError();
                            verifyAuthenticationResponse.value = { error: response._data }
                        } else {
                            verifyAuthenticationResponse.value = { success: true }
                            passkeyAssistantPage.value = 7
                            // passkeyAuth.authenticationTestReset();
                        }

                        passkeyPending.value = false
                    },
                    onRequestError({ error }) {
                        console.log(error)
                        verifyAuthenticationResponse.value = { error: error }
                        passkeyPending.value = false
                        // passkeyAuth.authenticationTestError();
                        // useError(error, 'Can\'t verify passkey');
                    },
                    onResponseError({ response }) {
                        console.log(response._data)
                        // passkeyAuth.authenticationTestError();
                        // useError(response, 'Can\'t verify passkey');
                        verifyAuthenticationResponse.value = { error: response._data }
                        passkeyPending.value = false
                    }
                });
            } catch(err) {
                console.log(err)
            }
        },

        finish() {
            passkeyStorage.value.consent = true
            passkeys.value.show = false
            // passkeyAuth.verifyAuthenticationResponse.value = 'error';
            // passkeyAuth.authenticationTestReset();
        },

        // authenticationTestError() {
        //     passkeyAuth.verifyAuthenticationResponse.value = 'error';
        //     passkeyAuth.authenticationTestReset();
        // },

        // authenticationTestReset() {
        //     setTimeout(() => {
        //         verifyAuthenticationResponse.value = null;
        //     }, 4567);
        // }
    };

    // if (process.client) {
    //     console.log('onErrorCapured')

    //     onErrorCaptured((err) => {
    //         console.log('onErrorCapured', err)
    //         error.value.show = true
    //         error.value.data = {...err}
    //         //error.value.data['cause']['url'] = window.location
    //     })
    // }

    // import { useAppStore } from '~/stores/app.js'
    // import { storeToRefs } from 'pinia'

    // const appStore = useAppStore()
    // const appStoreState = storeToRefs(appStore)
    // const { notifications } = storeToRefs(appStore)

    /* VUE LIFECYCLE HOOKS */
    onBeforeMount(async () => {
    });

    onMounted(async () => {
    });

    onUnmounted(async () => {

        if (passkeyStorage.value.consent === null && platformAuthenticatorIsAvailable()) {
            passkeys.value.show = true
        }
    });

</script>
<style lang="scss">
/* as */
</style>